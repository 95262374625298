import React from "react";
import { Div, Text, Button } from "atomize";

const SubscriptionPage = ( { gaAgent, token, onSubscribe} ) => {


  const Subscribe = () => {
    // Logic to redirect the user to the Stripe payment gateway URL generated by the backend API
    // You can implement this logic according to your backend setup
    // ReactGA4.initialize(process.env.REACT_APP_ARTM_GOOGLE_ANALYTICS_ID) 
    gaAgent.sendEvent('User Action', 'Subscription', 'subscription-submission')
    onSubscribe();
    
  };

  return (
    <Div d="flex" flexDir="column" align="center" justify="center" h="100vh">
      <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
        Subscription Terms
      </Text>
      <Div
        d="flex"
        flexDir="column"
        p="1rem"
        rounded="md"
        shadow="2"
        bg="white"
        w={{ xs: "90%", sm: "80%", md: "60%" }}
        maxW="800px"
      >
        <Text textSize="paragraph" m={{ b: "1rem" }}>
          Thank you for your interest in ARTM. By subscribing, you will gain access to exclusive features and content.
        </Text>
        <Text textSize="paragraph" m={{ b: "1rem" }}>
          Your subscription will include a 14-day, no obligation, free trial period. After the trial period ends, you will be asked to provide your credit card details if you choose to continue to use ARTM at a cost of $29 per month.
        </Text>
        <Button
          id='button-subscription-start'
          onClick={Subscribe}
          w="100%"
          bg="info700"
          hoverBg="info800"
          rounded="xs"
          p={{ l: "3rem", r: "2rem" }}
          m={{ b: "2rem" }}
        >
          Start Free Trial
        </Button>
      </Div>
    </Div>
  );
};

export default SubscriptionPage;
