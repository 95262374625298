import { Div, Text, Icon, Button, Notification } from "atomize";
import { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

function copyToClipboard(text) {
  navigator.clipboard.writeText(text)
    .then(() => {
      console.log("Text copied to clipboard:", text);
      // You can show a success message or perform any other desired actions here
    })
    .catch((error) => {
      console.error("Error copying text to clipboard:", error);
      // You can show an error message or handle the error in any other way here
    });
}

const PostCard = ({ gaAgent, post, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const removeNumberingAndHashtags = (post) => {
    const postWithoutNumbering = post.replace(/^\d+\. /, "");
    const postWithoutHashtags = postWithoutNumbering.replace(/#\w+/g, "");
    return postWithoutHashtags.trim();
  };
  
  const extractHashtags = (post) => {
    const hashtagsRegex = /#\w+/g;
    const hashtags = post.match(hashtagsRegex);
    return hashtags ? hashtags.join(" ") : "";
  };

  const post_content = removeNumberingAndHashtags(post)
  const post_tags = extractHashtags(post)
  const post_raw = String(post_content)+" "+String(post_tags)

  const handleCopyClick = () => {
    gaAgent.sendEvent('User Action', 'Copy Share Post', 'post-share-copy') 
    setIsOpen(true)
    copyToClipboard(post_raw);
    setTimeout(() => {
      setIsOpen(false)
    }, 3000); // 3 seconds
  };
  
  
  const extractHashtag = (post) => {
    const hashtagsRegex = /#\w+/;
    const match = post.match(hashtagsRegex);
    // console.log(match);
    return match ? match[0] : "";
  };

  const getHashtags = (post) => {
    const hashtagsRegex = /#\w+/g;
    const hashtags = post.match(hashtagsRegex);
    if (!hashtags) {
      return [];
    }
    
    const cleanedHashtags = hashtags.map((tag) => tag.replace(/^#/, ""));
    return cleanedHashtags;
  };

  const facebookShare = () => {
    gaAgent.sendEvent('User Action', 'Facebook Share Post', 'post-share-facebook') 
  }

  const twitterShare = () => {
    gaAgent.sendEvent('User Action', 'Twitter Share Post', 'post-share-twitter') 
  }

  const linkedInShare = () => {
    gaAgent.sendEvent('User Action', 'LinkedIn Share Post', 'post-share-linkedin') 
  }

  return (
     <Div
            m={{ b: "1rem" }}
            p="1rem"
            rounded="md"
            shadow="2"
            bg="white"
            w={{ xs: "100%", md: "100%" }}
            maxW="400px"
          >
            <Text tag="h5" textSize="title" m={{ b: "0.5rem" }}>
              Post {title}
            </Text>
            <Text tag="p" textSize="paragraph" m={{ b: "1rem" }}>
              {post_content}
            </Text>
            <Text tag="p" textSize="caption" textColor="gray800" m={{ b: "0.5rem" }}>
              {(post_tags)}
            </Text>
            <Div d="flex" justify="space-right">
            <Notification isOpen={isOpen} onClose={() => setIsOpen(false)}>
              Copied! 
            </Notification>
            <Button
              id='button-post-share-copy'
              onClick={handleCopyClick}
              h="2rem"
              w="2rem"
              bg="success700"
              hoverBg="success600"
              rounded="circle"
              // m={{ r: "1rem" }}
              shadow="2"
              hoverShadow="4"
            >
              <Icon name="Link" size="16px" color="white" />
            </Button>
              <FacebookShareButton
                id='button-post-share-facebook'
                url={'https://artm.ai'}
                quote={removeNumberingAndHashtags(post)}
                hashtags={extractHashtag(post)}
                onClick={facebookShare}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                id='button-post-share-twitter'
                title={removeNumberingAndHashtags(post)}
                url={'https://artm.ai'}
                hashtags={getHashtags(post)}
                onClick={twitterShare}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton
                id='button-post-share-linkedin'
                url={'https://artm.ai'}
                title={removeNumberingAndHashtags(post)}
                summary={removeNumberingAndHashtags(post)}
                source={removeNumberingAndHashtags(post)}
                onClick={linkedInShare}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              
            </Div>
          </Div>
  );
};

export default PostCard;
