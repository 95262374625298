import React, { useState, useEffect} from 'react';
import { Button, Div, Modal, Text, Input, Icon, Anchor, Tag, Checkbox, Label, Notification} from "atomize";
import axios from 'axios';

const Account = ({ gaAgent, token, pwdChange, userDelete, onBack, userDetails}) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [disablePWD, setDisablePWD] = useState(true)
    const [disableDelete, setDisableDelete] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false);
    const [isPasswordChangeFailure, setIsPasswordChangeFailure] = useState(false);
    const [passwordWordChangeMessage, setPasswordChangeMessage] = useState('')

    const [alertMismatch, setAlertMismatch] = useState(false);
    const [alertInvalid, setAlertInvalid] = useState(false);


    const handleDeleteAcknowledge = () => {
        setDisableDelete(!disableDelete);
    }

    useEffect(() => {
        userDetails()
          .then((result) => {
            setUsername(result.name)
            setEmail(result.email)
          })
          .catch((error) => {
            // Handle errors here
            console.log(error)
          });
      }, [userDetails]);

    const handleChangePassword = async () => {
      // Implement logic to change the user's password
      // You can use the 'oldPassword' and 'newPassword' states to update the password
      setIsPasswordChange(true)
      gaAgent.sendEvent('User Action', 'Password Change', 'account-passeword-change-submit')
      const response = await pwdChange(oldPassword, newPassword)
      if(!response.status){
        const message =  "FAILED : " + JSON.stringify(response.data.message).replace(/"/g, '');
        setPasswordChangeMessage(message)
        setIsPasswordChangeFailure(true)
        setDisablePWD(false); clearPWDInputs()
      }else if (response.status){
        const message =  "" + JSON.stringify(response.data).replace(/"/g, '');
        setPasswordChangeMessage(message)
        setIsPasswordChangeSuccess(true)
        // window.history.pushState({}, '', '');
        window.history.replaceState({}, '', window.location.href)
        setDisablePWD(false); clearPWDInputs()
        // setTimeout(() => onBack(), 1000);
      }
      
      
    //   TODO: Add sleep
      setIsPasswordChange(false)
    };

    useEffect(() => {
        // Check form validity and update disablePWD accordingly
        const regexPattern = /^[\S]+.*[\S]+$/;
        const pwdRegexPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
        const isPreviousPasswordValid = regexPattern.test(oldPassword);
        const isProposedPasswordValid = regexPattern.test(newPassword);
        const isPasswordValid = pwdRegexPattern.test(newPassword)
        const isMatch = newPassword === newPasswordConfirm
        const isFormValid = isPreviousPasswordValid && isProposedPasswordValid && isPasswordValid && isMatch; 

        if(newPassword.length > 0){
            setAlertInvalid(!isPasswordValid)
        }else{
            setAlertInvalid(false)
        }

        if(newPassword.length > 0 && newPasswordConfirm.length >= newPassword.length ){
            setAlertMismatch(!isMatch)
        }else{
            setAlertMismatch(false)
        }

        setDisablePWD(!isFormValid);
      }, [oldPassword, newPassword, newPasswordConfirm]);


    // TODO: Add Subscription Link 
    const subscriptionLink = 'https://artm.ai'

    const handleBack = () =>{
        onBack()
    }
  
    const handleDeleteAccount = () => {
      setIsDeleteModalOpen(true);
    };
  
    const handleConfirmDelete = async () => {
      gaAgent.sendEvent('User Action', 'Account Deletion', 'account-delete-submit')
      // TODO: Implement API request to remove user data   
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/user' || '/user';
      try{
        const response = await axios.delete(apiUrl,
            {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('ARTM_TOKEN')}`,
                  'Content-Type': 'application/json',
            },
        });
        if(response.status === 204){
            await userDelete();
        }
      } catch (error){
        console.error('An error occurred:', error)
      }
      setIsDeleteModalOpen(false);
    };
  
    const handleCancelDelete = () => {
      setIsDeleteModalOpen(false);
    };

    const clearPWDInputs = () => {
        setOldPassword('')
        setNewPassword('')
        setNewPasswordConfirm('')
    }

    // const handlePasswordIcon = () => {
    //     if ()
    // }
  
    return (
        <Div d="flex" flexDir="column" align="center" justify="center" h="100vh">
            <Notification
                id={isPasswordChangeSuccess ? 'account-password-change-success-notification':'account-password-change-failure-notification'}
                bg={isPasswordChangeSuccess ? "success700": "danger700"}
                isOpen={isPasswordChangeSuccess || isPasswordChangeFailure }
                onClose={() =>{}}
                suffix={
                    <Icon
                      name="Cross"
                      pos="absolute"
                      top="1rem"
                      right="0.5rem"
                      color="white"
                      size="18px"
                      cursor="pointer"
                      m={{ r: "0.5rem" }}
                      onClick={() =>{ isPasswordChangeSuccess ? setIsPasswordChangeSuccess(false) : setIsPasswordChangeFailure(false);}}
                    />
                }
            >
            {passwordWordChangeMessage}
            </Notification>
            <Text tag="h1">
            Account
            </Text>
            <Div
                d="flex"
                flexDir="column"
                p="1rem"
                rounded="md"
                shadow="2"
                bg="white"
                w={{ xs: "90%", sm: "50%", md: "40%" }}
            >
             <Text tag='h2' m={{b: '0.5rem'}}>Subscription</Text>
             <Anchor id="anchor-account-subscription-link" href={subscriptionLink} target="_blank">
                <Tag
                    hoverBg="info200"
                    m={{ r: "1rem", b: "1rem" }}
                    prefix={<Icon name="Link" size="12px" m={{ r: "0.25rem" }} />}
                    cursor="pointer"
                >
                    Subscription Management
                </Tag>
             </Anchor>
             <Text tag='h2' m={{t: "1rem", b: '0.5rem'}}>Change Password</Text>
             <form>
             {/* Hidden Username Field */}
             <Input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                value={email}
                style={{"display": "none"}}
                readOnly={true}
             />
             <Text tag="label">Old Password:</Text>
             <Input
              id="input-account-old-password"
              autoComplete="current-password"
              type="password"
              placeholder="Old Password"
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              m={{b: '0.5rem'}}
            />
            <Text tag="label">New Password:</Text>
            <Input
              id="input-account-new-password"
              autoComplete="new-password"
              type="password"
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}

            />
            {alertInvalid && 
            <Text textColor="danger600" m={{l: "0.2rem", b: "0.5rem"}}>
                Password must be at least 8 characters long with at least one number, special character, uppercase, and lowercase letter.
            </Text>}
            
            <Input
              id="input-account-new-password-confirm"
              autoComplete="new-password"
              type="password"
              placeholder="Confirm New Password"
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              value={newPasswordConfirm}
              m={{t: '0.1rem'}}

            />
            {alertMismatch && 
            <Text textColor="danger600" m={{l: "0.2rem", b: "0.5rem"}}>
                Passwords do not match
            </Text>}
            
            <Button 
                id="button-account-change-password-submit"
                disabled={disablePWD || isPasswordChange} 
                onClick={handleChangePassword} 
                bg="info700"
                m={{ t: '0.5rem', b: '1rem'}}
                prefix={isPasswordChange &&
                    <Icon
                    name="Loading"
                    size="16px"
                    color="white"
                    m={{ r: "0.5rem" }}
                    />
                }
                type="submit">
              Change Password
            </Button>
            </form>
            {/* <Button onClick={()=>{ setIsPasswordChangeSuccess(true); }}>
                Show success
            </Button>
            <Button onClick={()=>{ setIsPasswordChangeFailure(true); }}>
                Show failure
            </Button> */}
            <Text tag='h2' m={{t: "1rem"}}>Delete Account</Text>
            <Label align="center" textWeight="600" m={{ l: "1rem",t: "0.5rem", b:"0.5rem"}}>
                <Checkbox
                    id="checkbox-account-delete-enable"
                    onChange={handleDeleteAcknowledge}
                    checked={!disableDelete}
                />
                <Div d="flex" align="center">
                    <Icon name="AlertSolid" size="20px" color="warning700" />
                    <Text tag="p" m={{ l: '0.5rem' }}>
                        Deleting Your Account is Irreversible
                    </Text>
                </Div>
            </Label>
            {/* <Div d="flex" align="center" m={{ t: '0.5rem', b: '1rem' }}>
                <Icon name="AlertSolid" size="20px" color="warning700" />
                <Text tag="p" m={{ l: '0.5rem' }}>
                    Deleting Your Account is Irreversible
                </Text>
            </Div> */}
            
            <Button 
                id="button-account-delete-submit"
                onClick={handleDeleteAccount} 
                disabled={disableDelete}
                bg="danger700"
                m={{b: '1rem'}}>
            Delete Account
            </Button>
            <Button
            onClick={handleBack}
            w="100%"
            prefix={
                <Icon name="Back" size="16px" color="white" m={{ r: "0.5rem" }}
                />
            }
            bg="info700"
            p={{ l: "1rem", r: "0.5rem" }}
            m={{ t: '1rem', b: "1rem" }}
            >
            Back
            </Button>
            </Div>
            <Modal
            isOpen={isDeleteModalOpen}
            onClose={handleCancelDelete}
            align="center"
            >
            <Div style={{ padding: '2rem' }}>
                <Text textSize="subheader">{username +", are you sure you want to delete your account?"}</Text>
                <Div d="flex" justify="center" align="center" m={{ t: "1rem" }}>
                    
                    <Button
                        id="button-account-delete-modal-cancel"
                        m={{ r: '1rem', t: '1rem' }}
                        onClick={handleCancelDelete}
                        bg="gray700"
                        hoverBg="gray900"
                    >
                    Cancel
                    </Button>
                    <Button
                        id="button-account-delete-modal-submit"
                        m={{ t: '1rem' }}
                        onClick={handleConfirmDelete}
                        bg="danger700"
                        hoverBg="danger900"
                    >
                    Confirm Delete
                    </Button>
                </Div>
            </Div>
            </Modal>
        </Div>
    );
  };
export default Account;