import React, { useState } from 'react';
import { Button, Div, Icon, Text, Textarea, Input } from "atomize";
import axios from 'axios';

const Description = ({ gaAgent, token, onComplete, description }) => {
  const [id, setID] = useState(localStorage.getItem('id') || '');
  const [businessDescription, setBusinessDescription] = useState(localStorage.getItem('description') || '');
  const [name, setName] = useState(localStorage.getItem('name') || '')
  // const [tags, setTags] = useState(localStorage.getItem('tags') || '')
  const [style, setStyle] = useState(localStorage.getItem('style') || '')

  const [isLoading, setIsLoading] = useState(false);
  const [aboutResponse, setAboutResponse] = useState(null); 

  const totalDescription = 500
  const totalName = 50
  // const totalTags = 50
  const totalStyle = 150

  const remaingNameChars = totalName-name.length
  const remaingDescriptionChars = totalDescription-businessDescription.length
  // const remaingTagsChars = totalTags-tags.length
  const remaingStyleChars = totalStyle-style.length
  
  // const remainingCharacters = 500 - businessDescription.length;
  // const characterCountMessage = `${remainingCharacters} characters remaining`;
  const [buttonText, setButtonText] = useState("Submit");

  const handleTextChange = (e) => {

    setBusinessDescription(e.target.value);
  };

  const handleNameTextChange = (e) => {
    setName(e.target.value);
    localStorage.setItem('name', e.target.value)
  };

  // const handleTagsTextChange = (e) => {
  //   setTags(e.target.value);
  // };

  const handleStyleTextChange = (e) => {
    setStyle(e.target.value);
    localStorage.setItem('style', e.target.value)
  };
  
  const formValid = () => {
    let valid = false;

    if( businessDescription.length === 0)
      return valid
      
    if(remaingNameChars < 0 || remaingDescriptionChars < 0 || remaingStyleChars <0)
      return valid;

    if(aboutResponse){
      // Do nothing
    }
      
    return true
  }

  const handleAboutSubmit = async () => {
      gaAgent.sendEvent('User Action', 'Generate About', 'description-submit')
      try {
        setIsLoading(true);
        setButtonText("Thinking...");
        setTimeout(() => {
          setButtonText("Writing...");
          
          setTimeout(() => {
            setButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."
        

        const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL + '/api/v1/about' || '/api/v1/about'; // Use environment variable or default value
        if(id === '' || id == null){
          setID(1);
          localStorage.setItem('id', 1)
        }else{
          localStorage.setItem('id', id)
        }
        localStorage.setItem('description', businessDescription)
        localStorage.setItem('style', style)
        const response = await axios.post(apiUrl, {
          "id": id,
          "name": name,
          "description": businessDescription,
          "style": style
        }, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
      });
        setAboutResponse(response.data);
        localStorage.setItem('aboutResponse', JSON.stringify(response.data));
        onComplete(JSON.stringify(response.data))
        window.scrollTo(0, 0);
        setIsLoading(false);
        setButtonText("Submit");
      } catch (error) {
        
      }
    };

    return (
      <Div d="flex" flexDir="column" align="center" justify="center" h="100vh">
      
      <Div
        d="flex"
        flexDir="column"
        p="1rem"
        rounded="md"
        shadow="2"
        bg="white"
        w={{ xs: "90%", sm: "50%", md: "40%" }}
      >
      {/* Name */}
      <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
        Business Name
      </Text>
      <Input
        id="input-description-name"
        placeholder="Enter the name of your business"
        value={name}
        onChange={handleNameTextChange}
        m={{ b: "1rem" }}
        maxLength={totalName}
        minLength="1"
      />
      <Text
          tag="p"
          textSize="caption"
          textColor={remaingNameChars < 0 ? "danger700" : "gray500"}
          textAlign="right"
        >
          {remaingNameChars < 0 ? "Exceeded character limit" : `${remaingNameChars} characters remaining`}
        </Text>

      {/* Description */}
      <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
        Business Description
      </Text>
      <Textarea
        id="textarea-description-description"
        placeholder="Describe your bussiness"
        value={businessDescription}
        onChange={handleTextChange}
        m={{ b: "1rem" }}
        maxLength="500"
      />
      <Text
          tag="p"
          textSize="caption"
          textColor={remaingDescriptionChars < 0 ? "danger700" : "gray500"}
          textAlign="right"
        > 
          {remaingDescriptionChars < 0 ? "Exceeded character limit" : `${remaingDescriptionChars} characters remaining`}
        </Text>
      <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
        Post Style
      </Text>
      <Textarea
        id="input-description-style"
        placeholder="Describe the style you would like. e.g. Professional, Informal etc"
        value={style}
        onChange={handleStyleTextChange}
        m={{ b: "1rem" }}
        maxLength={totalStyle}
      />
      <Text
          tag="p"
          textSize="caption"
          textColor={remaingStyleChars < 0 ? "danger700" : "gray500"}
          textAlign="right"
        >
          {remaingStyleChars < 0 ? "Exceeded character limit" : `${remaingStyleChars} characters remaining`}
        </Text>
      <Button
        id='button-description-submit'
        onClick={handleAboutSubmit}
        disabled={isLoading || !formValid}
        w="100%"
        prefix={
          <Icon
            name={isLoading ? "Loading" : "Next"}
            left="1rem"
            size="16px"
            color="white"
            m={{ r: "0.5rem" }}
          />
        }
        bg="info700"
        hoverBg="info800"
        rounded="xs"
        p={{ l: "3rem", r: "2rem" }}
        m={{ b: "2rem" }}
      >
        {buttonText}
      </Button>
      </Div>
    </Div>
      
    )
}
export default Description;