import React from "react";
import ReactGA4 from "react-ga4";

class GAevents extends React.Component {
    constructor(props){
        super(props)
        var google_id = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_ARTM_GOOGLE_ANALYTICS_ID : "G-QTDW6B2K1P"
        ReactGA4.initialize(google_id) 
    }
    
    sendEvent = (category, action, label, value) => {
        ReactGA4.event({
            category: category, // Event category (required)
            action: action,      // Event action (required)
            label: label,           // Event label
            value: value
        });
    }

    render() {
        return null; // This component doesn't render anything
    }
}

export default GAevents;