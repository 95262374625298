import React, { useState } from 'react';
import { Modal, Textarea, Button, Icon, Div, Text, Input } from 'atomize';

const PropertySettings = ({ isOpen, onClose, onSubmit }) => {
  const [textValue, setTextValue] = useState('');

  const [name, setName] = useState(localStorage.getItem('name') || '')
  // const [tags, setTags] = useState(localStorage.getItem('tags') || '')
  const [style, setStyle] = useState(localStorage.getItem('style') || '')

  const totalName = 50
  // const totalTags = 50
  const totalStyle = 150

  const remaingNameChars = totalName-name.length
  // const remaingTagsChars = totalTags-tags.length
  const remaingStyleChars = totalStyle-style.length

  const handleNameTextChange = (e) => {
    setName(e.target.value);
  };

  // const handleTagsTextChange = (e) => {
  //   setTags(e.target.value);
  // };

  const handleStyleTextChange = (e) => {
    setStyle(e.target.value);
  };

  const handleSubmit = () => {
    localStorage.setItem('name', name)
    localStorage.setItem('style', style)
    onSubmit(textValue);
    setTextValue('');
    onClose();
  };

  return (
    <Div>
      <Modal isOpen={isOpen} onClose={onClose} align="center" rounded="md">
          <Icon
            name="Cross"
            pos="absolute"
            top="1rem"
            right="1rem"
            size="16px"
            onClick={onClose}
            cursor="pointer"
          />
            {/* Name */}
            <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
                Name
            </Text>
            <Input
                placeholder="Enter a name"
                value={name}
                onChange={handleNameTextChange}
                m={{ b: "1rem" }}
                maxLength={totalName}
            />
            <Text
                tag="p"
                textSize="caption"
                textColor={remaingNameChars < 0 ? "danger700" : "gray500"}
                textAlign="right"
                >
                {remaingNameChars < 0 ? "Exceeded character limit" : `${remaingNameChars} characters remaining`}
                </Text>

            {/* Tags */}
            {/* <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
                Name
            </Text>
            <Textarea
                placeholder="Enter a some tags"
                value={name}
                onChange={handleTagsTextChange}
                m={{ b: "1rem" }}
                maxLength="500"
            />
            <Text
                tag="p"
                textSize="caption"
                textColor={totalName-name.length < 0 ? "danger700" : "gray500"}
                textAlign="right"
                >
                {totalName-name.length < 0 ? "Exceeded character limit" : `${totalName-name.length} characters remaining`}
                </Text> */}

            {/* Style */}
            <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
                Style
            </Text>
            <Textarea
                placeholder="Describe the style you would like. e.g. Professional, Informal etc"
                value={style}
                onChange={handleStyleTextChange}
                m={{ b: "1rem" }}
                maxLength={totalStyle}
            />
            <Text
                tag="p"
                textSize="caption"
                textColor={remaingStyleChars < 0 ? "danger700" : "gray500"}
                textAlign="right"
                >
                {remaingStyleChars < 0 ? "Exceeded character limit" : `${remaingStyleChars} characters remaining`}
                </Text>
          <Div d="flex" justify="flex-end" m={{ t: "1rem" }}>
            <Button
              onClick={onClose}
              bg="gray200"
              textColor="medium"
              m={{ r: "1rem" }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} bg="info700">
              Yes, Submit
            </Button>
          </Div>
          {/* <Button
            onClick={handleSubmit}
            bg="info700"
            textColor="white"
            hoverBg="info800"
            rounded="md"
            w="100%"
          >
            Submit
          </Button> */}
      </Modal>
    </Div>
    
  );
};

export default PropertySettings;
