import React, {useState} from "react";
import axios from 'axios';
import { Div, Text, Button, Icon, Container, Row, Col } from "atomize";
import PostCard from "./post";
import EditModal from './edit';
import PropertySettings from "./propertySettings";

const Posts = ({ gaAgent, token, onBack }) => {
  
  const postResponse = JSON.parse(localStorage.getItem("postResponse"));
  const posts = postResponse ? postResponse.split("\n\n") : [];

  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [isLoadingSuggestEdit, setIsLoadingSuggestEdit] = useState(false);

  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const handleOpenSettingsModal = () => {
    setSettingsModalOpen(true);
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const handleSubmitSettingsModal = (textValue) => {
    // Handle the submission logic here
    // handleRefresh()
  };

  const [id, setID] = useState(localStorage.getItem('id') || '');

  const [suggestedEditButtonText, setSuggestedEditButtonText] = useState("Suggest an Edit");
  const [refreshButtonText, setRefreshButtonText] = useState("Refresh");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitModal = (textValue) => {
    // Handle the submission logic here
    handleSuggestEdit(textValue)
  };

  
  var isMobile = false;
  // const [isMobile, setIsMobile] = useState(false);
  
  const handleSuggestEdit = async (suggestedEdit) => {
    gaAgent.sendEvent('User Action', 'Edit Posts', 'post-edit-submit')
    try {
      setIsLoadingSuggestEdit(true)
      setSuggestedEditButtonText("Thinking");
        setTimeout(() => {
          setSuggestedEditButtonText("Writing");
    
          setTimeout(() => {
            setSuggestedEditButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."


      const businessDescription = localStorage.getItem('description');
      const name = localStorage.getItem('name');
      const style = localStorage.getItem('style');
      const aboutResponse = JSON.parse(localStorage.getItem('aboutResponse'));
      const poststResponse = JSON.parse(localStorage.getItem('postResponse'));
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/api/v1/posts' || '/api/v1/posts'; // Use environment variable or default value

      const response = await axios.put(apiUrl, {
        "id": id,
        "name": name,
        "style": style,
        "description": businessDescription, 
        "about": aboutResponse,
        "posts": poststResponse,
        "edit": suggestedEdit
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      localStorage.setItem('postResponse', JSON.stringify(response.data));
      // onComplete(JSON.stringify(response.data))
      window.scrollTo(0, 0);
      setIsLoadingSuggestEdit(false)
      setSuggestedEditButtonText("Suggest an edit");
    } catch (error) {
      console.error('Error generating posts:', error);
    } 
  };


  const handleGeneratePosts = async () => {
    gaAgent.sendEvent('User Action', 'Refresh Posts', 'post-refresh-submit')
    try {
      
      setIsLoadingRefresh(true)
      setRefreshButtonText("Thinking");
        setTimeout(() => {
          setRefreshButtonText("Writing");
    
          setTimeout(() => {
            setRefreshButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."
      const businessDescription = localStorage.getItem('description');
      const name = localStorage.getItem('name');
      const style = localStorage.getItem('style');
        // "name": name,
        // "style": style,
      const aboutResponse = JSON.parse(localStorage.getItem('aboutResponse'));
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/api/v1/posts' || '/api/v1/posts'; // Use environment variable or default value

      const response = await axios.post(apiUrl, {
        "id": id,
        "name": name,
        "style": style,
        "description": businessDescription,
        "about": aboutResponse},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      localStorage.setItem('postResponse', JSON.stringify(response.data));
      // onComplete(JSON.stringify(response.data))
      window.scrollTo(0, 0);
      setIsLoadingRefresh(false)
      setRefreshButtonText("Refresh");
    } catch (error) {
      console.error('Error generating posts:', error);
    } 
  };

  const handleBack = async () => {
    onBack();
    if(false){
      setID();
    }
  }

  if (window.innerWidth <= 768) {
    // setIsMobile(true);
    isMobile = true;
  }

  return (
    <Container>
      <EditModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
      <PropertySettings
        isOpen={isSettingsModalOpen}
        onClose={handleCloseSettingsModal}
        onSubmit={handleSubmitSettingsModal}
      />  
      <Row>
        <Div
          d="flex"
          align="center"
          justify="center"
          w="100%"
        >
          <Text tag="h1" textSize="heading" m={{ b: "1rem" }} justify="center" align="center">
            ARTM: Suggested Posts for your business
          </Text>
        </Div>
       
      </Row>
      {!isMobile &&
        <>
        <Row>
          <Col size="6">
            {posts.slice(0, 1).map((post) => (
              <PostCard gaAgent={gaAgent} post={post} title={"1"} key="1" />
            ))}
          </Col>
          <Col size="6">
            {posts.slice(1, 2).map((post) => (
              <PostCard gaAgent={gaAgent} post={post} title={"2"} key="2" />
            ))}
          </Col>

        </Row><Row>
            <Col size="6">
              {posts.slice(2, 3).map((post) => (
                <PostCard gaAgent={gaAgent} post={post} title={"3"} key="3" />
              ))}
            </Col>
            <Col size="6">
              {posts.slice(3, 4).map((post) => (
                <PostCard gaAgent={gaAgent} post={post} title={"4"} key="4" />
              ))}
            </Col>

          </Row>
        </> 
      }
      {isMobile &&
        <>
        
        {posts.map((post, index) => (
          <Div
            d="flex"
            align="center"
            justify="center"
            w="100%"
          >
            <PostCard gaAgent={gaAgent} post={post} title={index+1} key={index}/>
          </Div>
        ))}

        </> 
      }
      
      <Row>

      {/*  */}
      <Div
          d="flex"
          align="center"
          justify="center"
          w="100%"
        >
          <Div
            d="flex"
            flexDir="column"
            align="center"
            justify="center"
            p="1rem"
            rounded="md"
            bg="white"
            w={{ xs: "90%", sm: "60%", md: "40%" }}
            
          >
            <Button
              id='button-posts-suggest-edit'
              onClick={handleOpenModal}
              disabled={isLoadingRefresh || isLoadingSuggestEdit }
              w="100%"
              prefix={
                <Icon
                  name={isLoadingSuggestEdit ? "Loading" : "MessageSolid"}
                  size="18px"
                  color="white"
                  m={{ r: "0.5rem" }}
                />
              }
              bg="info700"
              hoverBg="info800"
              rounded="xs"
              p={{ l: "3rem", r: "2rem" }}
              m={{ b: "1rem" }}
            >
              {suggestedEditButtonText}
            </Button>
            <Button
              id='button-posts-refresh'
              onClick={handleGeneratePosts}
              disabled={isLoadingRefresh || isLoadingSuggestEdit }
              w="100%"
              bg={"success700"}
              hoverBg={"success800"}
              rounded="xs"
              p={{ l: "1rem", r: "0.5rem" }}
              m={{ b: "1rem" }}
              textColor="white"
              prefix={
                <Icon
                  name={isLoadingRefresh ? "Loading" : "Refresh"}
                  size="16px"
                  color="white"
                  m={{ r: "0.5rem" }}
                />
              }
            >
              {refreshButtonText}
            </Button>
            <Button
              id='button-posts-back'
              onClick={handleBack}
              disabled={isLoadingRefresh || isLoadingSuggestEdit}
              w="100%"
              prefix={
                <Icon name="Back" size="18px" color="white" m={{ r: "0.5rem" }}
                />
              }
              bg="info700"
              hoverBg="info800"
              rounded="xs"
              p={{ l: "1rem", r: "0.5rem" }}
              
            >
              Back
            </Button>
            <Button
              id='button-posts-settings'
              w="100%"
              onClick={handleOpenSettingsModal}
              prefix={
                <Icon name="Settings" size="16px" color="white" m={{ r: '0.5rem' }} />
              }
              bg="danger600"
              hoverBg="danger700"
              rounded="xs"
              p={{ l: '1.5rem', r: '1rem' }}
              m={{t: '1rem'}}
            >
              Settings 
            </Button>

          </Div>
      </Div>
      
      </Row>
    </Container>
  );  
};

export default Posts;
