import React, { useState } from 'react';
import { Modal, Textarea, Button, Icon, Div, Text } from 'atomize';

const EditModal = ({ isOpen, onClose, onSubmit }) => {
  const [textValue, setTextValue] = useState('');
  
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(textValue);
    setTextValue('');
    onClose();
  };

  const remainingCharacters = () => {
    return  (500 - textValue.length);
  }

  const remainingCharactersMessage = () => {
    const remainingCharacters = (500 - textValue.length ? 500 - textValue.length : 500);
    let characterCountMessage = `${remainingCharacters} characters remaining`;
    return characterCountMessage
  } 

  return (
    <Div>
      <Modal isOpen={isOpen} onClose={onClose} align="center" rounded="md">
          <Icon
            name="Cross"
            pos="absolute"
            top="1rem"
            right="1rem"
            size="16px"
            onClick={onClose}
            cursor="pointer"
          />
          <Text tag="h2" textSize="heading" m={{ b: "1rem" }}>
            Suggest an edit
          </Text>
          
          <Div>

            <Textarea
              id = "textarea-edit-input"
              placeholder="Describe how you would like ARTM to modify the text e.g. Make it shorter etc"
              value={textValue}
              onChange={handleTextChange}
              m={{ b: "1rem" }}
              maxLength="500"
            />
            
            <Text
              tag="p"
              textSize="caption"
              textColor={remainingCharacters() < 0 ? "danger700" : "gray500"}
              textAlign="right"
            >
              {remainingCharacters() < 0 ? "Exceeded character limit" : remainingCharactersMessage()}
            </Text>

          </Div>
          
          <Div d="flex" justify="flex-end" m={{ t: "1rem" }}>
            <Button
              id='button-edit-cancel'
              onClick={onClose}
              bg="gray200"
              textColor="medium"
              m={{ r: "1rem" }}
            >
              Cancel
            </Button>
            <Button id='button-edit-submit' onClick={handleSubmit} bg="info700">
              Yes, Submit
            </Button>
          </Div>
          {/* <Button
            onClick={handleSubmit}
            bg="info700"
            textColor="white"
            hoverBg="info800"
            rounded="md"
            w="100%"
          >
            Submit
          </Button> */}
      </Modal>
    </Div>
    
  );
};

export default EditModal;
