import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Div, Icon, Button } from 'atomize';
// import { Icon } from '@atomize/react-icons';

const NavTop = ({onLogout, onAccount, userDetails}) => {
    /* eslint-disable no-unused-vars */
    const [username, setUsername] = useState('');
    /* eslint-enable no-unused-vars */

    const [email, setEmail] = useState('');

    const handlLogout = async () => {
        onLogout();
      }

    const handleAccount = () => {
        onAccount();
    }

    useEffect(() => {
        userDetails()
          .then((result) => {
            setUsername(result.name)
            setEmail(result.email)
          })
          .catch((error) => {
            // Handle errors here
            console.log(error)
          });
      }, [userDetails]);
    
    const formatEmail = (email) => {
        if(email){
            const parts = email.split('@');
            const username = parts[0];
            const domain = parts[1];
            
            // Show the first 2 characters of the username
            const maskedUsername = username.slice(0, 2) + '*****';
        
            // Show the last 5 characters of the domain
            // const maskedDomain = '*****' + domain.slice(-5);
        
            // Combine the masked username and domain
            const maskedEmail = maskedUsername + '@' + domain;
            return maskedEmail;
            
        }
        
        
      };

    return (
        <Container>
        <Row d="flex" justify="space-between" align="center">
            <Col>
            {/* Your application logo or brand */}
            {/* <h1>Artm: </h1> */}
            </Col>
            <Col d="flex" justify="flex-end" gap="1rem">
            <Div>
                <Button id="button-topnav-account" className="nav-button" textColor="black" bg="white" m={{ r: "0.5rem" }} onClick={handleAccount}>
                <Icon name="UserCircle" size="20px" />
                {formatEmail(email)}
                </Button>
            </Div>
            <Div>
                {/* 'Logout' button */}
                <Button id="button-topnav-logout" className="nav-button" textColor="black" bg="white" m={{ r: "0.5rem" }} onClick={handlLogout}>
                <Icon name="Logout" size="20px" />
                Logout
                </Button>
            </Div>
            </Col>
        </Row>
        </Container>
    )
}
export default NavTop;