import React, { useEffect, useState } from 'react';
import { ThemeProvider, StyleReset, Container, Div } from 'atomize';
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Amplify, Auth  , Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import '@aws-amplify/ui-react/styles.css';
import axios from 'axios';
import RingLoader from "react-spinners/RingLoader";
import './App.css';

import GAevents from './Components/googleAnalytics';
import Description from './Components/description';
import Abouts from './Components/about';
import Posts from './Components/posts';
import SubscriptionPage from './Components/subscription';
import NavTop from './Components/navigationTop';
import Account from './Components/account';

const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();
const isProduction = process.env.NODE_ENV === 'production';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_ARTM_AWS_USER_POOL, //UserPool ID
    region: process.env.REACT_APP_ARTM_AWS_USER_REGION,          
    userPoolWebClientId: process.env.REACT_APP_ARTM_AWS_WEB_CLIENTID, //WebClientId
  },
  oauth: {
    domain: process.env.REACT_APP_ARTM_AWS_AUTH_URL,
    scope: [
      'openid'
    ],
    redirectSignIn: process.env.NODE_ENV === 'development' ? "https://dev.artm.ai:3000" : "https://app.artm.ai",
    redirectSignOut: process.env.NODE_ENV === 'development' ? "https://dev.artm.ai:3000" : "https://app.artm.ai",
    clientId: process.env.REACT_APP_ARTM_AWS_WEB_CLIENTID,
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
  
});


const engine = new Styletron();
const theme = {
  colors: {
    primary: 'info500',
    accent: 'success600',
  },
};
const gaAgent = new GAevents();

function App() {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isAccount, setIsAccount] = useState(false);
  const [id, setID] = useState(null);
  const [description, setDescription] = useState(null);
  const [tags, setTags] = useState(null);
  const [style, setStyle] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const signupParam = searchParams.get("signup");
  const isSignup = signupParam === 'true' ? 'signUp' : '';
  if(signupParam === 'true'){
    gaAgent.sendEvent('Page View', 'Page View: Registration', 'view-registration')
  }

  const [aboutResponse, setAboutResponse] = useState(null);
  const [postResponse, setPostResponse] = useState(null);

  const social_providers = process.env.NODE_ENV === 'development' ? ['facebook'] : ['']
  
  const handleDescriptionComplete = (response) => {
    setAboutResponse(response);
    // updateUser()
  };

  const handleAboutsComplete = (response) => {
    setPostResponse(response);
    // updateUser()
  };

  const handleRestart = () => {
    localStorage.setItem("name", '');
    localStorage.setItem("style", '');
    localStorage.setItem("description", '');
    localStorage.setItem("aboutResponse", '');
    localStorage.setItem("postResponse", '');
    setAboutResponse('');
    setPostResponse('');
  };

  const handleAboutBack = () => {
    localStorage.setItem("aboutResponse", '');
    setAboutResponse(null); 
  };

  const handlePostBack = () => {
    localStorage.setItem("postResponse", '');
    setPostResponse('');
    if(user){

    }
  };

  const handleAccountBack = () => {
    setIsAccount(false)
  }

  const handleSubscribe = async () => {
    const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/signup' || '/signup';
    await axios.post(apiUrl, {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ARTM_TOKEN')}`,
        'Content-Type': 'application/json',
      },
    });
    window.location.reload();
  };
  
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear()
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleAccount = () => {
    setIsAccount(!isAccount)

  }

  const handleUserDetails = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      
      const response = {
        "name": user.attributes.name,
        "email": user.attributes.email
      }
      return response
    } catch (error) {
      
    }
  }

  const handleChangePassword = async (oldPassword, newPassword) => {
    try {

      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPassword, newPassword);
      
      return { "status": true, "data": data};

    } catch(err) {
      console.log(err);
      return { "status": false, "data": err};
    }
  }

  const handleDeleteUser = async () => {
    try {
      const result = await Auth.deleteUser();
      setIsAccount(false);
      console.log(result);
    } catch (error) {
      console.log('Error deleting user', error);
    }
  }


  // const updateUser = async () =>{
  //   const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/login' || '/login'; // Use environment variable or default value
  //   if(user === null && !isLoadingUser ){
  //     setIsLoadingUser(true);
  //     const response = await axios.post(apiUrl, {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('ARTM_TOKEN')}`,
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       setUser(response.data);
  //       let user_subscription = response.data['user_subscription']
  //       setIsSubscribed(user_subscription)
  //       let user_properties = response.data['user_properties']
  //       if (user_properties.length > 1){
          
  //         if(user_properties[0].id != null || user_properties[0].id !== 'none'){
  //           setID(user_properties[0].id)
  //           localStorage.setItem('id', user_properties[0].id)
  //         }
  //         if(user_properties[0].name != null || user_properties[0].name !== 'none'){
  //           // setDescription(user_properties[0].description)
  //           localStorage.setItem('name', user_properties[0].name)
  //         }
  //         if(user_properties[0].description != null || user_properties[0].description !== 'none'){
  //           setDescription(user_properties[0].description)
  //           localStorage.setItem('description', user_properties[0].description)
  //         }
        
  //         if( user_properties[0].generated_about != null || user_properties[0].generated_about !== 'none'){
  //           setAboutResponse(user_properties[0].generated_about)
  //           localStorage.setItem('aboutResponse', JSON.stringify(user_properties[0].generated_about))
  //         }
  
  //         if(user_properties[0].generated_posts != null || user_properties[0].generated_posts!== 'none'){
  //           if(user_properties[0].generated_posts === 'none'){
  //             setPostResponse(null)
  //             localStorage.setItem('postResponse', null)
  //           }else{
  //             setPostResponse(user_properties[0].generated_posts)
  //             localStorage.setItem('postResponse', JSON.stringify(user_properties[0].generated_posts))
  //           }
            
            
  //         }
          
  //         if(user_properties[0].tags!= null || user_properties[0].tags!== 'none'){
  //           setTags(user_properties[0].tags)
  //           localStorage.setItem('tags',user_properties[0].tags)
  //         }
          
  //         if( user_properties[0].style != null || user_properties[0].style !== 'none'){
  //           setStyle(user_properties[0].style)
  //           localStorage.setItem('style', user_properties[0].style)
  //         }
  //     }
  //     setIsLoadingUser(false);
  //   }
    
  // }
  

  useEffect(() => {
    const getUserData = async () =>{
      if(user === null && !isLoadingUser ){
        setIsLoadingUser(true);
        
        const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/login' || '/login'; // Use environment variable or default value
        const response = await axios.post(apiUrl, {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ARTM_TOKEN')}`,
            'Content-Type': 'application/json',
          },
        });
        // const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/user' || '/user'; // Use environment variable or default value
        // const response = await axios.get(apiUrl,
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem('ARTM_TOKEN')}`,
        //     'Content-Type': 'application/json',
        //   },
        // });
        setUser(response.data);
        let user_subscription = response.data['user_subscription']
        setIsSubscribed(user_subscription)
        let user_properties = response.data['user_properties']
        // console.log("Length: "+JSON.stringify(user_properties.length))
        if (user_properties.length > 0){
          if(user_properties[0].id != null || user_properties[0].id !== 'none'){
            setID(user_properties[0].id)
            localStorage.setItem('id', user_properties[0].id)
          }
          if(user_properties[0].name != null || user_properties[0].name !== 'none'){
            // setDescription(user_properties[0].description)
            localStorage.setItem('name', user_properties[0].name)
          }
          if(user_properties[0].description != null || user_properties[0].description !== 'none'){
            setDescription(user_properties[0].description)
            localStorage.setItem('description', user_properties[0].description)
          }
        
          if( user_properties[0].generated_about != null || user_properties[0].generated_about !== 'none'){
            setAboutResponse(user_properties[0].generated_about)
            localStorage.setItem('aboutResponse', JSON.stringify(user_properties[0].generated_about))
          }
  
          if(user_properties[0].generated_posts != null || user_properties[0].generated_posts !== 'none'){
            if(user_properties[0].generated_posts === 'none'){
              localStorage.setItem('postResponse', null)
              setPostResponse(null)
            }else{
              setPostResponse(user_properties[0].generated_posts)
              localStorage.setItem('postResponse', JSON.stringify(user_properties[0].generated_posts))
            }
            
          }
          
          if(user_properties[0].tags!= null || user_properties[0].tags !== 'none'){
            setTags(user_properties[0].tags)
            localStorage.setItem('tags',user_properties[0].tags)
          }
          
          if( user_properties[0].style != null || user_properties[0].style !== 'none'){
            setStyle(user_properties[0].style)
          localStorage.setItem('style', user_properties[0].style)
          }
        }else{
          setID('')
          localStorage.removeItem('id')
          setDescription('')
          localStorage.removeItem('description')
          setAboutResponse('')
          localStorage.removeItem('aboutResponse')
          setPostResponse('')
          localStorage.removeItem('postResponse')
          setTags('')
          localStorage.removeItem('tags')
          setStyle('')
          localStorage.removeItem('style')
        }
        setIsLoadingUser(false);
      }
      
    }

    const checkUserSession = async () => {
      try {
        // const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        // const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        // localStorage.setItem('ARTM_TOKEN', user.signInUserSession.accessToken.jwtToken);
        // localStorage.setItem('ARTM_USER', JSON.stringify(user.attributes));
        localStorage.setItem('ARTM_TOKEN',idToken);
        // if (process.env.NODE_ENV==='development'){
        //   localStorage.setItem('ARTM_TOKEN',accessToken);
        // }

        setToken(idToken)
        await getUserData();
        window.history.replaceState({}, document.title, window.location.pathname);
      } catch (error) {
        console.log('User not signed in');
        setUser(null);
      }
    };

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) =>{
      // console.log(event);
      // eslint-disable-next-line default-case
      switch (event) {
        case 'signIn':
          // let accessToken = data.signInUserSession.accessToken.jwtToken
          let idToken = data.signInUserSession.idToken.jwtToken
          // GA Event: Sign-in
          gaAgent.sendEvent('User Action', 'Sign-in', 'user-sign-in')
          localStorage.setItem('ARTM_TOKEN',idToken);
          setToken(idToken)
          break;
        case 'signOut':
          setToken(null)
          setUser(null);
          gaAgent.sendEvent('User Action', 'Sign-out', 'user-sign-out')
          break;
        case 'configured':
          // setToken(accessToken)
          // localStorage.setItem('ARTM_TOKEN',accessToken);
          // gaAgent.sendEvent('Page View', 'Login Page', 'login')
          break;
        case 'tokenRefresh':
          console.log(data)
          // let idRefreshToken = data.signInUserSession.idToken.jwtToken
          // localStorage.setItem('ARTM_TOKEN',idRefreshToken);
          // setToken(accessToken)
          // localStorage.setItem('ARTM_TOKEN',accessToken);
          break;
        case 'signUp':
          // User registration event
          // GA Event: Register
          gaAgent.sendEvent('User Action', 'Registration', 'Registration')
          break;
      }
    });
    // console.log(user)
    // if (user === null && !isLoadingUser) {
    //   console.log("Before run")
    //   let result = 
    //   console.log(result)
    //   console.log("After run")
    // }
    checkUserSession();
    return unsubscribe;
  }, [token, isLoadingUser, user, id, description, aboutResponse, postResponse, style, tags]);


  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <StyletronProvider value={engine} debug={debug} debugAfterHydration>
        <ThemeProvider theme={theme}>
        <StyleReset />
        {/* socialProviders={['facebook']} */}
        <Authenticator initialState={isSignup} socialProviders={social_providers} variation="modal" loginMechanisms={['email', 'name']}>
          {({ signOut, user }) => (
            <Container>
                <NavTop onLogout={handleLogout} onAccount={handleAccount} userDetails={handleUserDetails}/>
                {isLoadingUser && <Div d="flex" flexDir="column" align="center" justify="center" h="100vh"><RingLoader color="#36d7b7" /></Div>}
                {!isLoadingUser && !isAccount && !isSubscribed && <SubscriptionPage gaAgent={gaAgent} token={token} onSubscribe={handleSubscribe} />}
                {!isLoadingUser && !isAccount  && isSubscribed && !aboutResponse && <Description gaAgent={gaAgent} token={token} onComplete={handleDescriptionComplete}/>}
                {!isLoadingUser && !isAccount  && isSubscribed && aboutResponse && !postResponse && <Abouts gaAgent={gaAgent} token={token} onComplete={handleAboutsComplete} onRestart={handleRestart} onBack={handleAboutBack}/>}
                {!isLoadingUser && !isAccount  && isSubscribed && aboutResponse && postResponse && <Posts gaAgent={gaAgent} token={token} onRestart={handleRestart} onBack={handlePostBack}/>}
                {isAccount && !isLoadingUser && <Account gaAgent={gaAgent} token={token} pwdChange={handleChangePassword} userDelete={handleDeleteUser} onBack={handleAccountBack} userDetails={handleUserDetails}/>}
            </Container>
          )}
          </Authenticator>  
          
        </ThemeProvider>
      </StyletronProvider>

    </CacheBuster>
    
  );
}

export default App;
