import React, { useState } from 'react';
import { Button, Container, Div, Icon, Text } from "atomize";
import EditModal from './edit';
import PropertySettings from './propertySettings';
import axios from 'axios';

const Abouts = ({ gaAgent, token, onComplete, onBack }) => {
  // const [isEditMode, setIsEditMode] = useState(false);
  const [isLoadingSubmit, setIsSubmitLoading] = useState(false);
  const [isLoadingSuggestEdit, setIsSuggestEditLoading] = useState(false);
  const [isLoadingRefresh, setIsRefreshLoading] = useState(false);
  const [aboutText, setAboutText] = useState(JSON.parse(localStorage.getItem('aboutResponse')));

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  
  const [id, setID] = useState(localStorage.getItem('id') || '');

  const [suggestedEditButtonText, setSuggestedEditButtonText] = useState("Suggest an Edit");
  const [submitButtonText, setSubmitButtonText] = useState("Submit");
  const [refreshButtonText, setRefreshButtonText] = useState("Refresh");
  
  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleSubmitEditModal = (textValue) => {
    // Handle the submission logic here
    handleSuggestEdit(textValue);
    // console.log('Submitted text:', textValue);
  };

  const handleOpenSettingsModal = () => {
    setSettingsModalOpen(true);
  };

  const handleCloseSettingsModal = () => {
    setSettingsModalOpen(false);
  };

  const handleSubmitSettingsModal = (textValue) => {
    // Handle the submission logic here
    // handleRefresh()
  };

  const handleSuggestEdit = async (suggestedEdit) => {
    gaAgent.sendEvent('User Action', 'Edit About', 'about-edit-submission');

    try {
      
      setIsSuggestEditLoading(true)
      setSuggestedEditButtonText("Thinking");
        setTimeout(() => {
          setSuggestedEditButtonText("Writing");
            
          setTimeout(() => {
            setSuggestedEditButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."
      const businessDescription = localStorage.getItem('description');
      setID(localStorage.getItem('id')) 
      const name = localStorage.getItem('name');
      const style = localStorage.getItem('style');
        // "name": name,
        // "style": style,
      // const aboutResponse = JSON.parse(localStorage.getItem('aboutResponse'));
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/api/v1/about' || '/api/v1/about'; // Use environment variable or default value
      
      const response = await axios.put(apiUrl, {
        "id": id,
        "name": name,
        "description": businessDescription,
        "style": style,
        "about": aboutText,
        "edit": suggestedEdit},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      localStorage.setItem('aboutResponse', JSON.stringify(response.data));
      setAboutText(response.data)
      setIsSuggestEditLoading(false)
      setSuggestedEditButtonText("Suggest an edit")
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error generating posts:', error);
    } 
  };

  const handleGeneratePosts = async () => {
    gaAgent.sendEvent('User Action', 'Generate Posts', 'about-submission')
    try {
      setIsSubmitLoading(true)
      setSubmitButtonText("Thinking");
        setTimeout(() => {
          setSubmitButtonText("Writing");
    
          setTimeout(() => {
            setSubmitButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."
        
      const businessDescription = localStorage.getItem('description');
      setID(localStorage.getItem('id')) 
      // const id = localStorage.getItem('id');
      const name = localStorage.getItem('name');
      const style = localStorage.getItem('style');
        // "name": name,
        // "style": style,
      const aboutResponse = JSON.parse(localStorage.getItem('aboutResponse'));
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL+'/api/v1/posts' || '/api/v1/posts'; // Use environment variable or default value

      const response = await axios.post(apiUrl, {
        "id": id,
        "name": name,
        "style": style,
        "description": businessDescription,
        "about": aboutResponse},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      localStorage.setItem('postResponse', JSON.stringify(response.data));
      onComplete(JSON.stringify(response.data))
      window.scrollTo(0, 0);
      setIsSubmitLoading(false)
      setSubmitButtonText("Submit");
    } catch (error) {
      console.error('Error generating posts:', error);
    } 
  };

  const handleRefresh = async () => {
    gaAgent.sendEvent('User Action', 'Refresh About', 'about-refresh-submit')
    try {
      setIsRefreshLoading(true);
      setRefreshButtonText("Thinking");
        setTimeout(() => {
          setRefreshButtonText("Writing");
    
          setTimeout(() => {
            setRefreshButtonText("Polishing");
          }, 3000); // 4 seconds for "writing..."
        }, 3000); // 4 seconds for "thinking..."
      var businessDescription = localStorage.getItem('description');
      setID(localStorage.getItem('id')) 
      const name = localStorage.getItem('name');
      const style = localStorage.getItem('style');
        // "name": name,
        // "style": style,
      
      const apiUrl = process.env.REACT_APP_ARTM_AI_API_URL + '/api/v1/about' || '/api/v1/about'; // Use environment variable or default value
      const response = await axios.post(apiUrl, {
        "id": id,
        "name": name,
        "style": style,
        "description": businessDescription}, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
    });
      localStorage.setItem('aboutResponse', JSON.stringify(response.data));
      setAboutText(response.data)
      window.scrollTo(0, 0);
      setIsRefreshLoading(false);
      setRefreshButtonText("Refresh");
    } catch (error) {
      
    }
  };

  const handleBack = async () => {
    onBack();
  }

  return (
    <Container>
      <EditModal
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            onSubmit={handleSubmitEditModal}
          />    
      <PropertySettings
            isOpen={isSettingsModalOpen}
            onClose={handleCloseSettingsModal}
            onSubmit={handleSubmitSettingsModal}
          />   
      <Div d="flex" flexDir="column" align="center" justify="center" h="100%" w="100%">
        <Text tag="h1" textSize="heading" m={{ b: "1rem" }}>
          ARTM: Description of your business
        </Text>
        
        <Div
          d="flex"
          flexDir="column"
          p="1rem"
          rounded="md"
          shadow="2"
          bg="white"
          w={{ xs: "100%", sm: "100%", md: "100%" }}
        >
          
          {aboutText && aboutText.split('\n').map((about_snippet, index) => (
            <Text textSize="paragraph" m={{ b: '1rem' }} key={index}>
              {about_snippet}
            </Text>
          ))}

        <Div
          d="flex"
          align="center"
          justify="center"
          w="100%"
        >
        <Div
            d="flex"
            flexDir="column"
            align="center"
            justify="center"
            p="1rem"
            rounded="md"
            bg="white"
            w={{ xs: "90%", sm: "60%", md: "40%" }}
            
          >
        <Button
          id='button-about-suggest-edit'
          onClick={handleOpenEditModal}
          disabled={isLoadingSubmit || isLoadingRefresh || isLoadingSuggestEdit}
          w="100%"
          prefix={
            <Icon
              name={isLoadingSuggestEdit ? "Loading" : "MessageSolid"}
              size="18px"
              color="white"
              m={{ r: "0.5rem" }}
            />
          }
          bg="info700"
          hoverBg="info800"
          rounded="xs"
          p={{ l: "3rem", r: "2rem" }}
          m={{ b: "1rem" }}
        >
          {suggestedEditButtonText}
        </Button>
        <Button
          id='button-about-submit'
          onClick={handleGeneratePosts}
          disabled={isLoadingSubmit || isLoadingRefresh || isLoadingSuggestEdit}
          w="100%"
          prefix={
            <Icon
              name={isLoadingSubmit ? "Loading" : "Next"}
              
              size="18px"
              color="white"
              m={{ r: "0.5rem" }}
            />
          }
          bg="info700"
          hoverBg="info800"
          rounded="xs"
          p={{ l: "3rem", r: "2rem" }}
          m={{ b: "1rem" }}
        >
          {submitButtonText}
        </Button>
        <Button
          id='button-about-refresh'
          onClick={handleRefresh}
          disabled={isLoadingSubmit || isLoadingSuggestEdit || isLoadingRefresh}
          w="100%"
          bg={"success700"}
          hoverBg={"success800"}
          rounded="xs"
          p={{ l: "1rem", r: "0.5rem" }}
          textColor="white"
          m={{ b: "1rem" }}
          prefix={
            <Icon
              name={isLoadingRefresh ? "Loading" : "Refresh"}
              size="16px"
              color="white"
              m={{ r: "0.5rem" }}
            />
          }
        >
          {refreshButtonText}
        </Button>
        <Button
          id='button-about-back'
          onClick={handleBack}
          disabled={isLoadingRefresh || isLoadingSubmit || isLoadingSuggestEdit}
          w="100%"
          prefix={
            <Icon name="Back" size="16px" color="white" m={{ r: "0.5rem" }}
            />
          }
          bg="info700"
          hoverBg="info800"
          rounded="xs"
          p={{ l: "1rem", r: "0.5rem" }}
          m={{ b: "1rem" }}
        >
          Back
        </Button>
        <Button
          id='button-about-settings'
          w="100%"
          onClick={handleOpenSettingsModal}
          prefix={
            <Icon name="Settings" size="16px" color="white" m={{ r: '0.5rem' }} />
          }
          bg="danger600"
          hoverBg="danger700"
          rounded="xs"
          p={{ l: '1.5rem', r: '1rem' }}
        >
          Settings 
        </Button>
        </Div>

        </Div>

        </Div>
      </Div>
    </Container>
  );


}
export default Abouts;